.pure-material-textfield-outlined {
  --pure-material-safari-helper1: #dadada !important;
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: var(
    --pure-material-font,
    "Roboto",
    "Segoe UI",
    BlinkMacSystemFont,
    system-ui,
    -apple-system
  );
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  overflow: hidden;
}
.classValid{
  --pure-material-safari-helper1valid: red !important;
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: var(
    --pure-material-font,
    "Roboto",
    "Segoe UI",
    BlinkMacSystemFont,
    system-ui,
    -apple-system
  );
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  overflow: hidden;
}

/* Input, Textarea */
.pure-material-textfield-outlined > select {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px; /* Safari */
  border-color: #dadada;
  border-top-color: transparent;
  border-radius: 4px;
  padding: 15px 13px 15px;
  width: 100%;
  height: inherit;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  background-color: transparent;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transition: border 0.2s, box-shadow 0.2s;
}
.classValid > select {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px; /* Safari */
  border-color: red;
  opacity: 0.7;
  border-top-color: transparent;
  border-radius: 4px;
  padding: 15px 13px 15px;
  width: 100%;
  height: inherit;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  background-color: transparent;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transition: border 0.2s, box-shadow 0.2s;
}
/* Span */
.pure-material-textfield-outlined > select + span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  width: 100%;
  max-height: 100%;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}
.classValid > input + span
{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  width: 100%;
  max-height: 100%;
  color: red;
  opacity: 0.7;
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}
/* Corners */
.pure-material-textfield-outlined > select + span::before,
.pure-material-textfield-outlined > select + span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: #dadada;
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}
.classValid > input + span::before,
.classValid > input + span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: red;
  opacity: 0.7;
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}
.pure-material-textfield-outlined > select + span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.classValid > select + span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.pure-material-textfield-outlined > select + span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px;
}

.classValid > select + span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px;
}
/* Hover */

.pure-material-textfield-outlined:hover > select {
  border-color: #dadada;
  border-top-color: transparent;
}
.classValid:hover > input,
.classValid:hover > textarea,
.classValid:hover > select {
  border-color: red;
  opacity: 0.7;
  border-top-color: transparent;
}



.pure-material-textfield-outlined:hover > select + span::before,
.pure-material-textfield-outlined:hover > select + span::after {
  border-top-color: #dadada;
}
.classValid:hover > select + span::before,
.classValid:hover > select + span::after {
  border-top-color: red;
  opacity: 0.7;
}

.pure-material-textfield-outlined:hover > select:not(:focus):placeholder-shown {
  border-color: #dadada;
}

.classValid:hover > select:not(:focus):placeholder-shown {
  border-color: red;
  opacity: 0.7;
}
/* Placeholder-shown */

.pure-material-textfield-outlined > select:not(:focus):placeholder-shown {
  border-top-color: #dadada;
}

.classValid > select:not(:focus):placeholder-shown {
  border-top-color: red;
  opacity: 0.7;
}

.pure-material-textfield-outlined
  > select:not(:focus):placeholder-shown
  + span {
  font-size: inherit;
  line-height: 68px;
}


.classValid
  > select:not(:focus):placeholder-shown
  + span {
  font-size: inherit;
  line-height: 68px;
}


.pure-material-textfield-outlined
  > select:not(:focus):placeholder-shown
  + span::before,
.pure-material-textfield-outlined
  > select:not(:focus):placeholder-shown
  + span::after {
  border-top-color: transparent;
}


.classValid
  > select:not(:focus):placeholder-shown
  + span::before,
.classValid
  > select:not(:focus):placeholder-shown
  + span::after {
  border-top-color: transparent;
}


/* Focus */

.pure-material-textfield-outlined > select:focus {
  border-color: #dadada;
  border-top-color: transparent;
  box-shadow: inset 1px 0 var(--pure-material-safari-helper1),
    inset -1px 0 var(--pure-material-safari-helper1),
    inset 0 -1px var(--pure-material-safari-helper1);
  outline: none;
}

.classValid > select:focus {
  border-color: red;
  opacity: 0.7;
  border-top-color: transparent;
  box-shadow: inset 1px 0 var(--pure-material-safari-helper1),
    inset -1px 0 var(--pure-material-safari-helper1),
    inset 0 -1px var(--pure-material-safari-helper1);
  outline: none;
}

.pure-material-textfield-outlined > select:focus + span {
  color: #267871;
  font-weight: 600;
}

.classValid > select:focus + span {
  color: red;
  font-weight: 600;
}


.pure-material-textfield-outlined > select:focus + span::before,
.pure-material-textfield-outlined > select:focus + span::after {
  border-top-color: var(--pure-material-safari-helper1) !important;
  box-shadow: inset 0 1px var(--pure-material-safari-helper1);
}

.classValid > select:focus + span::before,
.classValid > select:focus + span::after {
  border-top-color: var(--pure-material-safari-helper1) !important;
  box-shadow: inset 0 1px var(--pure-material-safari-helper1);
}
/*  */

/* Disabled */

.pure-material-textfield-outlined > select:disabled,
.pure-material-textfield-outlined > select:disabled + span {
  border-color: #dadada !important;
  border-top-color: transparent !important;
  color: #dadada;
  pointer-events: none;
}

.classValid > select:disabled,
.classValid > select:disabled + span {
  border-color: red !important;
  border-top-color: transparent !important;
  color: red;
  pointer-events: none;
}


.pure-material-textfield-outlined > select:disabled + span::before,
.pure-material-textfield-outlined > select:disabled + span::after {
  border-top-color: #dadada !important;
}

.classValid > select:disabled + span::before,
.classValid > select:disabled + span::after {
  border-top-color: red !important;
}


.pure-material-textfield-outlined > select:disabled:placeholder-shown,
.pure-material-textfield-outlined > select:disabled:placeholder-shown + span {
  border-top-color: #dadada !important;
}


.classValid > select:disabled:placeholder-shown,
.classValid > select:disabled:placeholder-shown + span {
  border-top-color: red !important;
}


.pure-material-textfield-outlined
  > select:disabled:placeholder-shown
  + span::before,
.pure-material-textfield-outlined
  > select:disabled:placeholder-shown
  + span::after {
  border-top-color: transparent !important;
}

.classValid
  > select:disabled:placeholder-shown
  + span::before,
.classValid
  > select:disabled:placeholder-shown
  + span::after {
  border-top-color: transparent !important;
}
/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
   
    .pure-material-textfield-outlined > select,
    .pure-material-textfield-outlined > select + span,
    .pure-material-textfield-outlined > select + span::before,
    .pure-material-textfield-outlined > select + span::after {
      transition-duration: 0.1s;
    }
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .classValid > select,
    .classValid > select + span,
    .classValid > select + span::before,
    .classValid > select + span::after {
      transition-duration: 0.1s;
    }
  }
}