/* .mainlayout
{
    display: flex;
    width: 100%;
    background : #fafafa;
    margin-top: 80px;

} */
.loaderdiv
{
    display: flex;
    height: 100vh;
    background: '#ff0000';
    z-index: 1000
}
.selectionoverlay
{
    z-index: 100;
}
.productselectionoverlay
{
    z-index: 101;
}
.dropdownlayout * .menu {
    position: relative !important;

  }

.gemselectionoverlay
{
    z-index: 20;
}


.hidden { display:none; }

.masteroverlay
{
    z-index: 11;
}
.suboverlay
{
    z-index: 3;
}
.wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.selectmargin
{
    margin-top: 8px;
    z-index:99;

}

.filepond--item {
    width: calc(25% - 0.25em);
}
.filepond--root .filepond--drop-label {
    cursor: pointer;
    background: #e2dbdb;
}
.filepond--drop-label.filepond--drop-label label {
    cursor: pointer;
}
@media only screen and (max-width: 425px) {
    .filepond--item {
        width: calc(100% - 0.25em);
    }
}