.custom-file-upload {
    /* border: 1px solid #ccc; */
    /* background-color: rgba(152, 119, 189, 1); */
    width:100px;
    height:100px;
    border-radius: 5px;
    border:2px solid  rgba(60, 64, 67, 0.15);
    color: #fff;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
  }

  .custom-file-input{
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 134px;
    height: 134px;
    z-index: 1;
  }
  .number_input {
    border-radius: 5px;
    height: 40px;
    box-shadow: rgb(215 215 217 / 20%) 0px 7px 29px 0px;
    outline: none;
    border: 1px solid #bbb5b5;
    max-width: 30%;
    margin: 0 auto;
    display: block;
    text-indent: 18px;
}