.card2 {

    z-index: 0;
    overflow: hidden;
}
.card2:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px -8px -8px rgba(38, 38, 38, 0.2);
      top: -4px;
      background-color: white;
    }
  
    .card2:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: -16px;
      right: -16px;
      background: #00838d;
      height: 32px;
      width: 32px;
      box-shadow: 0px -8px -8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border-radius: 32px;
      transform: scale(2);
      transform-origin: 50% 50%;
      transition: transform 0.15s ease-out;
    }
  
    .card2:hover:before {
      transform: scale(2.15);
    }
  
  